import { z } from 'zod';
import { activityTypes } from '../constants';

export const activitySchema = z.object({
    id: z.coerce.number().int(),
    description: z.string().optional().nullable(),
    lead_id: z.coerce.number().int().optional().nullable(),
    opportunity_id: z.coerce.number().int().optional().nullable(),
    subject: z.string().trim().min(1, { message: 'Subject is required' }),
    type: z.enum(activityTypes),
    user_id: z.string(),
    organization_id: z.coerce.number().int(),
    b2b_company_id: z.coerce.number().int().optional().nullable(),
    b2b_contact_id: z.coerce.number().int().optional().nullable(),
    contact_id: z.coerce.number().int().optional().nullable(),
    mobile_phone: z.string().optional().nullable(),
    created_at: z.coerce.date(),
    updated_at: z.coerce.date(),
});

export const createActivitySchema = activitySchema.pick({
    description: true,
    lead_id: true,
    opportunity_id: true,
    subject: true,
    type: true,
    contact_id: true,
    mobile_phone: true,
    b2b_company_id: true,
    b2b_contact_id: true,
});

// Define types for call logs
export const callLogTypes = {
    ATTEMPTED_TO_CALL: 'attempted to call',
    MISSED_CALL: 'missed call',
    CALLED: 'called',
} as const;

export type CallLogType = (typeof callLogTypes)[keyof typeof callLogTypes];
