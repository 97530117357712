import { ref, watch } from 'vue';

export interface JSEP {
    type: string;
    sdp: string;
}
interface Message {
    result?: {
        event?: string;
        username?: string;
        displayname?: string;
    };
}
interface OfferParams {
    success?: () => void;
    error?: (error: Error) => void;
    audio?: boolean;
    video?: boolean;
    [key: string]: any;
}

export function useCallHandler() {
    const {
        handler,
        context,
        showCallPopup,
        muted,
        callStatus,
        contact,
        remoteMedia,
        credentials,
        running,
        connected,
        calling,
        phoneNumber,
        hangingUp,
        jsep: jsepStore,
    } = storeToRefs(useJanusStore());
    const janusStore = useJanusStore();
    const history = ref<string[]>([]);

    const { data: contactsPaginated } = useFetch('/api/contacts', {
        query: {
            page: 1,
            limit: 9999999999999,
            sort: computed(() => 'updated_at'),
            order: computed(() => 'asc'),
        },
        headers: useRequestHeaders(['cookie']),
        key: 'contacts-janus',
    });

    watch(contactsPaginated, () => {
        console.log(contactsPaginated.value);
    });

    const determineCallLogType = (): { type: CallLogType; subject: string } => {
        if (history.value.includes('accepted')) {
            return {
                type: callLogTypes.CALLED,
                subject: `Called ${contact.value ? contact.value?.first_name : 'Unknown Number'}`,
            };
        } else if (history.value.includes('progress') && history.value.includes('hangup')) {
            return {
                type: callLogTypes.ATTEMPTED_TO_CALL,
                subject: `Attempted to call ${contact.value ? contact.value?.first_name : 'Unknown Number'}`,
            };
        } else if (history.value.includes('incomingcall') && history.value.includes('hangup')) {
            return {
                type: callLogTypes.MISSED_CALL,
                subject: `Missed call from ${contact.value ? contact.value?.first_name : 'Unknown Number'}`,
            };
        }
        return {
            type: callLogTypes.MISSED_CALL,
            subject: `Missed call from ${contact.value ? contact.value?.first_name : 'Unknown Number'}`,
        };
    };

    const addCallLog = async () => {
        try {
            console.log(contact.value);
            const { type, subject } = determineCallLogType();
            await $fetch('/api/activities', {
                method: 'POST',
                body: {
                    type,
                    subject,
                    lead_id: contact.value?.lead_id,
                    contact_id: contact.value?.company_id ? contact.value?.id : undefined,
                    mobile_phone: contact.value?.mobile_phone ?? phoneNumber.value,
                    b2b_contact_id: contact.value?.b2b_company_id ? contact.value?.id : undefined,
                    b2b_company_id: contact.value?.b2b_company_id,
                },
            });
            await refreshNuxtData('recent-calls');
            if (contact.value?.b2b_company_id) {
                await refreshNuxtData(`activities-${contact.value?.b2b_company_id}`);
            }
        } catch (err) {
            console.error(err);
        } finally {
            history.value = [];
            phoneNumber.value = '';
            contact.value = null;
        }
    };

    const start = async () => {
        try {
            const { default: JanusManager } = await import('janus-manager');
            const manager = new JanusManager();

            context.value = await manager.init({
                server: credentials.value?.server as string,
                longPollTimeout: 60000,
            });

            context.value.error = (error: Error) => {
                console.log(error.message);
                toast.error(`Initialization Error: ${error.message}`);
            };

            running.value = true;
        } catch (err) {
            console.error('Start Error:', err);
            toast.error('Failed to start call service');
        }
    };

    const connect = async () => {
        if (connected.value) {
            toast.error('Already connected.');
            return;
        }

        try {
            const { default: JanusManager } = await import('janus-manager');
            const manager = new JanusManager();

            await manager.init({ server: credentials.value?.server as string, longPollTimeout: 60000 });

            handler.value = await manager.createCallHandle({
                mediaState: (medium: string, on: boolean) => {
                    const status = on ? 'started' : 'stopped';
                    console.info('Janus mediaState:', `Janus ${status} receiving our ${medium}`);
                },
                webrtcState: (on: boolean) => {
                    const status = on ? 'up' : 'down';
                    console.info('Janus webrtcState:', `Janus says our WebRTC PeerConnection is ${status}`);
                },
            });

            if (!handler.value) {
                throw new Error('Failed to create call handle');
            }

            handler.value.on('message', async (msg: Message, jsep?: JSEP) => {
                try {
                    console.info('message event:', msg?.result?.event, msg.result, jsep);
                    const event = msg?.result?.event;

                    if (event === 'registered') {
                        connected.value = true;
                        toast.success('Successfully registered with call server.');
                    } else if (event === 'registration_failed') {
                        console.error('Registration failed:', msg);
                        toast.error('Registration failed');
                    } else if (event === 'hangup') {
                        hangingUp.value = false;
                        history.value.push('hangup');
                        calling.value = false;
                        callStatus.value = 'ended';
                        addCallLog();
                        showCallPopup.value = false;
                    } else if (event === 'accepted') {
                        history.value.push('accepted');
                        callStatus.value = 'accepted';
                        if (jsep) {
                            handler.value?.handleRemoteJsep(jsep);
                        }
                    } else if (event === 'progress') {
                        history.value.push('progress');
                        callStatus.value = 'calling';
                        if (jsep) {
                            handler.value?.handleRemoteJsep(jsep);
                        }
                    } else if (event === 'proceeding') {
                        if (jsep) {
                            handler.value?.handleRemoteJsep(jsep);
                        }
                    } else if (event === 'incomingcall') {
                        history.value.push('incomingcall');
                        jsepStore.value = jsep!;
                        callStatus.value = 'incoming';
                        showCallPopup.value = true;
                        const sipUri = msg.result?.username || 'Unknown Number';
                        const phoneNumberMatch = sipUri.match(/sip:(\+?\d+)@/);
                        const filteredContact = contactsPaginated.value?.result.filter(
                            (contact) =>
                                formatPhoneNumber(contact.mobile_phone as string) ===
                                formatPhoneNumber(phoneNumberMatch ? phoneNumberMatch[1] : 'Unknown Number')
                        );

                        contact.value = filteredContact ? filteredContact[0] : null;

                        phoneNumber.value = phoneNumberMatch ? phoneNumberMatch[1] : 'Unknown Number';
                    }
                } catch (error) {
                    console.error('Message handler error:', error);
                    toast.error('Error processing message');
                }
            });

            handler.value.on('remotestream', async (stream: MediaStream) => {
                try {
                    if (remoteMedia.value) {
                        remoteMedia.value.srcObject = stream;
                        remoteMedia.value.play().catch((err) => {
                            console.error('Error playing remote stream:', err);
                        });
                    } else {
                        throw new Error('remoteMedia video element is undefined');
                    }
                } catch (error) {
                    console.error('Remote stream error:', error);
                    toast.error('Error connecting to remote stream');
                }
            });

            try {
                handler.value.sendMessage('register', {
                    proxy: credentials.value?.proxy,
                    secret: credentials.value?.secret,
                    username: credentials.value?.username,
                    display_name: credentials.value?.display_name,
                });
                toast.success('Registration message sent to call server.');
            } catch (error) {
                console.error('Registration error:', error);
                toast.error('Failed to register with call server');
                throw error;
            }
        } catch (error) {
            console.error('Connection error:', error);
            toast.error('Failed to establish connection');
        }
    };

    const callHandler = (number: string) => {
        const callNumber = `sip:${number}@pipeline.pbx002.ofon.biz`;
        phoneNumber.value = number;

        if (!handler.value) {
            throw new Error('Call handler not initialized');
        }

        if (!contact.value) {
            const filteredContact = contactsPaginated.value?.result.filter(
                (contact) => formatPhoneNumber(contact.mobile_phone as string) === formatPhoneNumber(number)
            );
            console.log(filteredContact);

            contact.value = filteredContact ? filteredContact[0] : null;
        }

        if (number === '') {
            toast.error('Please enter a phone number');
            return;
        }

        const config: Omit<OfferParams, 'success' | 'error'> = {
            audio: true,
            video: false,
        };

        handler.value
            .makeCall(callNumber, config)
            .then(() => {
                calling.value = true;
            })
            .catch((error: unknown) => {
                console.error('Make Call Error:', error);
                toast.error('Failed to make call');
            });
    };

    const hangupCall = () => {
        try {
            if (!handler.value) {
                throw new Error('Call handler not initialized');
            }

            hangingUp.value = true;
            handler.value?.hangup();
        } catch (error) {
            console.error('Hangup call error:', error);
        }
    };

    const acceptCall = async () => {
        try {
            if (!handler.value) {
                throw new Error('Call handler not initialized');
            }

            await handler.value.answerCall(jsepStore.value!, { media: { audio: true, video: false } });
            callStatus.value = 'accepted';
        } catch (error) {
            console.error('Accept call error:', error);
        }
    };

    const declineCall = async () => {
        try {
            if (!handler.value) {
                throw new Error('Call handler not initialized');
            }

            await handler.value.declineCall();
            handler.value?.hangup();
            callStatus.value = 'ended';
            showCallPopup.value = false;
            jsepStore.value = null;
        } catch (error) {
            console.error('Decline call error:', error);
        }
    };

    const toggleMute = async () => {
        try {
            if (!handler) {
                throw new Error('Call handler not initialized');
            }

            if (muted.value) {
                handler.value?.unmuteAudio();
            } else {
                handler.value?.muteAudio();
            }

            muted.value = !muted.value;
        } catch (error) {
            console.error('Mute toggle error:', error);

            try {
                if (handler) {
                    muted.value = handler.value?.isAudioMuted() as boolean;
                }
            } catch (e) {
                console.error('Failed to get audio state:', e);
                muted.value = false;
            }
        }
    };

    watch(calling, () => {
        if (calling.value) {
            showCallPopup.value = true;
            callStatus.value = 'initiating';
        } else if (!calling.value) {
            showCallPopup.value = false;
            callStatus.value = 'ended';
        }
    });

    const disconnect = async () => {
        try {
            if (!handler.value) {
                throw new Error('Call handler not initialized');
            }

            if (calling.value) {
                await handler.value.hangup();
            }

            handler.value.detach();

            janusStore.reset();

            toast.success('Disconnected from call server.');
        } catch (error) {
            console.error('Disconnect error:', error);
            toast.error('Failed to disconnect');
        }
    };

    return {
        start,
        connect,
        callHandler,
        running,
        connected,
        calling,
        phoneNumber,
        remoteMedia,
        showCallPopup,
        muted,
        toggleMute,
        callStatus,
        hangupCall,
        acceptCall,
        declineCall,
        contact,
        hangingUp,
        disconnect,
    };
}
